import React, { useState, useEffect } from "react";
import logo from "./assets/images/logo.svg";
import axios from "axios";
import 'bootstrap-icons/font/bootstrap-icons.css';


//const apiUrl = 'http://0.0.0.0:8001';

//const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl = "https://ai.indapoint.com";


// if (process.env.NODE_ENV === 'development') {
//   console.log('Running in development mode');
//   // Development-only logic here
// } else if (process.env.NODE_ENV === 'production') {
//   console.log('Running in production mode');
//   // Production-only logic here
// }

// console.log('API URL:', apiUrl);

const AdsWithAI = () => {
  const [url, setUrl] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [validUrl, setValidUrl] = useState(false);
  const [buttonText, setButtonText] = useState('Submit');
  const [hasError, setHasError] = useState(false);
  const [iconNumbers, setIconNumbers] = useState({
  images: Math.floor(Math.random() * 10), // Random initial number for images
    fonts: Math.floor(Math.random() * 10), // Random initial number for fonts
    colors: Math.floor(Math.random() * 10) // Random initial number for colors
  });

  let eventSource;

  useEffect(() => {
    if (loading) {
      setButtonText('Loading...');
    } else if (hasError) {
      setButtonText('Failed. Try again?');
    } else {
      setButtonText('Submit');
    }
  }, [loading, hasError]); // Depend on loading and hasError
  

  
  const validateUrl = (inputUrl) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(inputUrl);
  };

  const [responseData, setResponseData] = useState({});
// Remove the duplicate declaration of 'setButtonText'



const handleInputChange = (e) => {
  const inputUrl = e.target.value;
  setUrl(inputUrl);
  setValidUrl(validateUrl(inputUrl));
};



const createVideoConfig = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${apiUrl}/video/CreateVideo?&url=${url}&CampaignId=5627`,
    headers: {
      accept: "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjaGlyYWciLCJleHAiOjE5NjYzMTkxNDF9.R9G8wRB5maJzZSw8Fnt57chxvJ9iegPQturzdCp-dnE",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateUrl(url)) {
      alert("Please enter a valid URL.");
      return;
    }
    setLoading(true);
    setHasError(false); // Ensure this is reset at the start of a submission


    try {
      const response = await requestVideo(createVideoConfig,setButtonText);
      console.log("🚀 ~ handleSubmit ~ response:", response);

      setData(response.data);

      // Check if the status is "Processing Completed"
      if (response.data.Status === "Completed") {
        setLoading(false);
        setButtonText('Submit'); // Reset button text or set to a success message
      } else {
        // If not completed, call the second API after 10 minutes
        setTimeout(() => {
          callSecondAPI(response?.data?.UniqueID,response?.data?.BrandingUniqueID);
        }, 10000); // 10 minutes in milliseconds
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setHasError(true); // Indicate that an error has occurred
      setButtonText('Failed. Try again?'); // This should now persist
      setData({});
      //alert("Oops, something went wrong.");
    }
  };

  const requestVideo = async (createVideoConfig, setButtonText) => {
    const MAX_RETRY = 5;
    let counter = 0;
  
    while (counter < MAX_RETRY) {
      try {
        const response = await axios.request(createVideoConfig);
        if (response.data.Status === 'Waiting' || response.data.error_code === 1 || response.data.Status === '') {
          counter++;
          setButtonText(`Retrying... (${counter}/${MAX_RETRY})`);
          await new Promise(resolve => setTimeout(resolve, 10000)); // Wait for 10 seconds before retrying
        } else {
          // If the response is successful and doesn't require retrying, return it
          setButtonText('Processing Data');
          //we should call second api here

          return response;
        }
      } catch (error) {
        console.error('Request failed:', error);
        counter++;
        if (counter >= MAX_RETRY) {
          // If max retries reached, update button text to indicate failure
          setButtonText('Failed after max retries');
          throw new Error('Max retries reached');
        }
        await new Promise(resolve => setTimeout(resolve, 10000)); // Wait before retrying in case of error
      }
    }
  
    // If the function exits the loop without returning, it means max retries were exceeded without success
    throw new Error('Max retries reached without success');
  };



  let waitingCounter = 0; // Assuming waitingCounter is reset elsewhere when starting the whole process

  const callSecondAPI = async (uniqueID,BrandingUniqueID) => {
    const secondApiConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiUrl}/video/videocreationstatus?unique_id=${uniqueID}&BrandingUniqueID=${BrandingUniqueID}`,
      headers: {
        accept: "application/json",
        // Authorization header, if required
      },
    };
  
    try {
      const response = await axios.request(secondApiConfig);
      console.log("🚀 ~ callSecondAPI ~ response:", response.data);
      
      // Update the state with the response data
      setData(response.data);
  
  
      switch (response.data.Status) {
        case "Waiting":
          if (waitingCounter < 2) {
            waitingCounter++;
            setTimeout(() => callSecondAPI(uniqueID,BrandingUniqueID), 10000); // Retry after 10 seconds
          } else {
            console.log("Maximum 'Waiting' retries reached.");
            setButtonText('Trying again.');
            //call requestVideo again to retry
            requestVideo(createVideoConfig, setButtonText);
            
            // Handle max retries reached scenario here
          }
          break;
        case "Processing":
          // Continuously check status without incrementing waitingCounter
          setTimeout(() => callSecondAPI(uniqueID,BrandingUniqueID), 10000); // Check status again after 10 seconds
          break;
        case "Error":
          // Handle error scenario
          setLoading(false);
          setButtonText('Error encountered. Please try again.');
          setData({}); // Consider clearing data or setting error details
          break;
        case "Completed":
          // Handle completion scenario
          setLoading(false);
          setButtonText('Submit'); // Indicate completion
          break;
        default:
          // Handle any other unexpected status
          console.log("Unexpected status received.");
          setLoading(false);
          setButtonText('Unexpected status. Please try again.');
          break;
      }
    } catch (error) {
      console.error("Error during video status check:", error);
      setLoading(false);
      setButtonText('Failed. Try again?');
      setData({});
    }
  };
  

  const CallBrandingStatusAPI = async (uniqueID) => {
    const BrndingAPIConfig = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiUrl}/video/brandinglog?unique_id=?unique_id=${uniqueID}`,
      headers: {
        accept: "application/json",
        // Authorization header, if required
      },
    };

    try {
      const response = await axios.request(BrndingAPIConfig); // Ensure BrndingAPIConfig is correctly defined
      console.log("🚀 ~ CallBrandingStatusAPI ~ response:", response.data);
      
      if (response && response.data) {
        setData({
          images: response.data.no_of_images,
          fonts: response.data.no_of_fonts, // Assuming the correct key is no_of_fonts
          colors: response.data.brands_colors // Assuming the correct key is brands_colors
        });
      }
    } catch (error) {
      console.error("Error during video status check:", error);
    }
  };




  useEffect(() => {
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);

  const renderView = (msg) => {
    const { step, text, image_url } = msg;
    if (typeof msg === "string") return <p>{msg}</p>;

    return (
      <div className="container">
        <div className="row">
          <div className="img-item col-md-2">
            <img
              src={image_url}
              alt={step}
              className="img-fluid rounded w-100"
            />
            <p className="small mb-0 mt-4">{text}</p>
          </div>
        </div>
      </div>
    );
  };

  const ProgressDisplay = ({ data }) => {
    return (
        <>
            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#28a745' }}>
                {data.Status || "Start Creating your Amazing Video"}
            </p>

            {data.percent_complete >= 100 && data?.final_video_url ? (
                          <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#28a745' }}>

                <div className="mt-5">
                    Video Created. Click URL: <a href={data.final_video_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#007bff' }}>{data.final_video_url}</a>
                </div>
                </p>
            ) : (
                <>
                    {data.status_image_URL && data.percent_complete < 100 && (
                        <img
                            src={data.status_image_URL}
                            alt="Status"
                            className="img-fluid"
                            style={{ maxWidth: '100px', marginRight: '10px', verticalAlign: 'middle' }}
                        />
                    )}
                    {data.percent_complete > 0 && data.percent_complete < 100 && (
                        <div className="progress" style={{ margin: "20px 0", height: '20px' }}>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${data.percent_complete}%`, backgroundColor: '#007bff' }}
                                aria-valuenow={data.percent_complete}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {data.percent_complete}%
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

  
  const URLForm = ({ url, setUrl, validUrl, loading, buttonText, handleSubmit }) => {
    return (
      <div className="form-wrap">
        <input
          className="form-control"
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter valid website URL with https://"
          style={{ marginRight: "10px" }}
          disabled={loading}
        />
        <button
          type="submit"
          disabled={!validUrl || loading}
          onClick={handleSubmit}
        >
          {buttonText}
        </button>
      </div>
    );
  };
  return (
    <section className="w-100">
      <div className="top">
        <div className="container">
          <img src={logo} alt="" className="" width={200} />
        </div>
      </div>
      <div className="container py-5 content-wrap">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="" id="chat1">
              <p className="fw-bold title">AdsWithAi Demo</p>
              <div className="form-wrap">
                <p className="error">
                  {messages.map((msg) => renderView(msg))}
                </p>
                <ProgressDisplay data={data} />

                <>
                  <input
                    className="form-control"
                    type="text"
                    value={url || "https://www.indapoint.com/"}
                    onChange={handleInputChange}
                    placeholder="Enter valid website URL with https://"
                    style={{ marginRight: "10px" }}
                  />
                  <button
                    type="submit"
                    disabled={!url || loading}
                    onClick={handleSubmit}
                  >
                    {loading ? buttonText : "Submit"}
                  </button>
                </>

                {
  data?.final_video_url ? (
    <div className="mt-5">
      <video width="100%" controls>
        <source src={data.final_video_url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  ) : (
    <div className="squares-container mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {/* Image Square */}
      <div className="square me-2" style={{ 
        border: '2px solid white', 
        width: '100px', 
        height: '100px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#f8f9fa', 
        position: 'relative'
      }}>
        <span style={{ 
          color: 'red', 
          position: 'absolute', 
          top: '-5px',
          fontSize: '18px',
          zIndex: 2
        }}>
          {data.no_of_images || 'N/A'}
        </span>
        <i className="bi bi-images" style={{ 
          color: '#000', 
          fontSize: '4rem', // Adjust icon size here
          zIndex: 1
        }}></i>
      </div>

      {/* Fonts Square */}
      <div className="square me-2" style={{ 
        border: '2px solid white', 
        width: '100px', 
        height: '100px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#f8f9fa', 
        position: 'relative'
      }}>
        <span style={{ 
          color: 'red', 
          position: 'absolute', 
          top: '10px',
          fontSize: '16px',
          zIndex: 2
        }}>
          {data.no_of_fonts || 'N/A'}
        </span>
        <i className="bi bi-fonts" style={{ 
          color: '#000', 
          fontSize: '4rem', // Adjust icon size here
          zIndex: 1
        }}></i>
      </div>

      {/* Colors Square */}
      <div className="square" style={{ 
        border: '2px solid white', 
        width: '100px', 
        height: '100px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#f8f9fa', 
        position: 'relative'
      }}>
        <span style={{ 
          color: 'red', 
          position: 'absolute', 
          top: '10px',
          fontSize: '16px',
          zIndex: 2
        }}>
          {data.colors || 'N/A'}
        </span>
        <i className="bi bi-palette" style={{ 
          color: '#000', 
          fontSize: '4rem', // Adjust icon size here
          zIndex: 1
        }}></i>
      </div>
    </div>
  )
}


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>










  );

  
};



export default AdsWithAI;
